<template>
  <div>
    <div class="complaint-target--wrapper p-4 bg-white rounded">
      <div class="header mb-2 pb-2 border-0 border-solid border-b-2 border-gray-200 flex justify-between" v-if="target">
        <h3 class="text-base">Şikayet Edilen {{ complaintType }}</h3>
        <vs-td id="actions">
          <vx-tooltip text="İşlemler">
            <vs-button
              :to="contentLink"
              color="secondary"
              size="small"
              class="btn btn--action"
              type="gradient"
              icon="open_in_browser"
            ></vs-button>
          </vx-tooltip>
        </vs-td>
      </div>
      <div v-if="list.length">
        <div v-if="target && $query.type === 'entry'">
          <div id="dio-title" v-if="target.topic">
            <strong id="entrytopic">{{ target.topic.topic }}</strong>
          </div>
          <div id="entryhtml" class="mt-3 whitespace-pre-wrap " v-text="target.entry.entryData"></div>
          <div class="flex break-word my-2" v-if="target.entry.entryImageUrls && target.entry.entryImageUrls.length">
            <div v-for="(img, index) in target.entry.entryImageUrls" :key="index" class="w-1/5">
              <img :src="getImageUrl(img, '75x75')" class="rounded image-entry pl-0 p-2" />
            </div>
          </div>
        </div>
        <div v-if="target && $query.type === 'topic'">{{ target.topic }}</div>
        <div v-if="target && $query.type === 'user'">
          <div class="flex flex-col">
            <div class="username">
              <strong>Kullanıcı Adı:</strong>
              {{ target.username }}
            </div>
            <div class="email">
              <strong>Email:</strong>
              {{ target.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Complaint class="mt-10" />
  </div>
</template>

<script>
import { generateImageUrl } from '@/utils/imageHash';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
import Complaint from '@/pages/complaint/Complaint';
export default {
  components: { Complaint },
  mixins: [usePagination({ moduleName: 'complaint' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('complaint', { list: 'listDetail', target: 'listDetailTarget' }),
    contentLink() {
      return `/dashboard/${this.$query.type}?id=${this.$query.userId}`;
    },
    complaintType() {
      const types = {
        user: 'Kullanıcı',
        entry: 'Dio',
        topic: 'Başlık'
      };
      const type = types[this.$query.type];
      return type;
    }
  },

  methods: {
    ...mapActions('complaint', { getList: 'getDetail' }),
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.search;
      delete this.$query.tab;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('id')) return; // id varsa default query param ekleme (yani url'e tab ekleme)
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: 'entry' } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },

    filterList() {
      this.$vs.loading();
      this.getList(this.fetchParams).finally(() => {
        this.$vs.loading.close();
      });
    },

    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      if (this.$query.hasOwnProperty('id')) {
        await this.filterList();
      }
    },
    handleDeleteUser($event, payload) {
      if (confirm('Kullanıcıyı silmek istediğinize emin misiniz?')) {
        $event.stopPropagation();
        this.deleteUser('action', { action: 'deleteUser', payload });
        this.$vs.notify({
          color: 'success',
          text: 'Kullanıcı silindi.'
        });
      } else {
        this.$vs.notify({
          color: 'danger',
          text: 'Kullanıcı silinemedi.'
        });
      }
    },
    handleContentLink($event, id) {
      $event.stopPropagation();
      this.$router.push({ name: 'user', query: { id } });
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style>
.tab-btn + .tab-btn {
  margin: 5px;
}
</style>
