<template>
  <div>
    <vs-table :data="data" noDataText="">
      <template>
        <vs-th sort-key="topic">Şikayet Başlığı</vs-th>
        <vs-th sort-key="user">Şikayet Eden</vs-th>
        <vs-th sort-key="description">Açıklama</vs-th>
        <vs-th sort-key="createDate">Tarih</vs-th>
      </template>
      <template>
        <tbody>
          <vs-tr :data="complaintData">
            <vs-td id="topic" v-if="complaintData.complaintTopic">
              {{ complaintData.complaintTopic.topic }}
            </vs-td>
            <vs-td id="user" v-if="complaintData.complainantUser">
              {{ complaintData.complainantUser.username }}
            </vs-td>
            <vs-td id="description">
              {{ complaintData.description }}
            </vs-td>
            <vs-td id="createDate">
              <p class="font-medium w-24">
                {{ complaintData.updateDate | moment('DD-MM-YYYY') }}
                <br />
                {{ complaintData.updateDate | moment('HH:mm') }}
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import ComplaintService from '@/services/ComplaintService';
export default {
  data() {
    return {
      complaintData: [],
      data: []
    };
  },
  mixins: [usePagination({ moduleName: 'complaint' }), useQueryParams, usePageRefresh],
  computed: {
    contentLink() {
      return `/dashboard/${this.$query.type}?id=${this.$query.id}`;
    },
    complaintType() {
      const types = {
        user: 'Kullanıcı',
        entry: 'Dio',
        topic: 'Başlık'
      };
      const type = types[this.$query.type];
      return type;
    }
  },

  methods: {
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.search;
      delete this.$query.tab;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('id')) return; // id varsa default query param ekleme (yani url'e tab ekleme)
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: 'entry' } });
      }
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
    },
    async getListDetail() {
      const result = await ComplaintService.getByComplaintDetail(this.$query.id);
      this.complaintData = result.data;
    }
  },
  async created() {
    this.fetchData();
    this.getListDetail();
  }
};
</script>
