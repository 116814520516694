var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        { attrs: { data: _vm.data, noDataText: "" } },
        [
          [
            _c("vs-th", { attrs: { "sort-key": "topic" } }, [
              _vm._v("Şikayet Başlığı")
            ]),
            _c("vs-th", { attrs: { "sort-key": "user" } }, [
              _vm._v("Şikayet Eden")
            ]),
            _c("vs-th", { attrs: { "sort-key": "description" } }, [
              _vm._v("Açıklama")
            ]),
            _c("vs-th", { attrs: { "sort-key": "createDate" } }, [
              _vm._v("Tarih")
            ])
          ],
          [
            _c(
              "tbody",
              [
                _c(
                  "vs-tr",
                  { attrs: { data: _vm.complaintData } },
                  [
                    _vm.complaintData.complaintTopic
                      ? _c("vs-td", { attrs: { id: "topic" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.complaintData.complaintTopic.topic) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm.complaintData.complainantUser
                      ? _c("vs-td", { attrs: { id: "user" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.complaintData.complainantUser.username
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _c("vs-td", { attrs: { id: "description" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.complaintData.description) +
                          "\n          "
                      )
                    ]),
                    _c("vs-td", { attrs: { id: "createDate" } }, [
                      _c("p", { staticClass: "font-medium w-24" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.complaintData.updateDate,
                                "DD-MM-YYYY"
                              )
                            ) +
                            "\n              "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.complaintData.updateDate,
                                "HH:mm"
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }